import React, { Component } from 'react';
import { connect } from 'react-redux';

import { scrollTo } from 'components/modules/_misc';
import _svg from 'components/modules/svg';
import withRouter from 'components/Wrappers/withRouter';
import { UserActions } from 'reducers/user';

import Home from 'views/Public/shared-content/Home';

class MN_Reciprocity extends Component {
	componentDidMount() {
		let { location, logout } = this.props,
			{ logging_out } = location.state || {};

		if (logging_out) logout();

		scrollTo();
	}

	render() {
		let { navigate } = this.props,
			{ REACT_APP_ENV } = process.env;

		return (
			<Home
				mainContent={
					<>
						<h1>
							<span>Wisconsin</span>-Minnesota Reciprocity
						</h1>

						{REACT_APP_ENV !== 'production' && (
							<h2>{`${REACT_APP_ENV === 'practice' ? 'Practice' : 'Development'} Application`}</h2>
						)}

						<div>
							<input
								type='button'
								onClick={() => navigate('/create-account')}
								className='btn-general primary'
								value='Create an account'
							/>
							<input
								type='button'
								onClick={() => navigate('/sign-in')}
								className='btn-general'
								value='sign in'
							/>
						</div>
					</>
				}
				faqs={[
					{
						header: 'What is reciprocity?',
						item: (
							<p>
								Reciprocity is a program that allows Wisconsin residents to attend public institutions
								in other states at a reduced tuition rate, similar to what they would pay if they were
								attending a school in Wisconsin. This arrangement is typically based on agreements
								between states to provide mutual benefits to their residents. For example, the
								Minnesota-Wisconsin Reciprocity Tuition Program allows students from Wisconsin to attend
								Minnesota public colleges and universities at in-state tuition rates. This program is
								supported by{' '}
								<a href='https://docs.legis.wisconsin.gov/statutes/statutes/36/27' target='__blank'>
									Wisconsin State Statutes, section 36.27
								</a>
								.
							</p>
						)
					},
					{
						header: 'Who should use this application?',
						item: (
							<>
								<p>Wisconsin residents attending Minnesota public institutions.</p>
								<p>
									The Minnesota/Wisconsin reciprocity agreement allows Wisconsin residents to attend
									Minnesota public universities at in-state tuition rates.
								</p>
								<p>
									This online reciprocity application is designed for Wisconsin residents attending
									Minnesota public institutions. Applying online through our application is the
									easiest way to apply for reciprocity benefits.
								</p>
							</>
						)
					},
					{
						header: 'Who should not use this application?',
						item: (
							<>
								<p>Minnesota residents.</p>
								<ul>
									<li>
										Minnesota residents should apply through the{' '}
										<a href='https://www.ohe.state.mn.us/' target='__blank'>
											Minnesota Office of Higher Education
										</a>
										.
									</li>
								</ul>
								<p>
									Students attending a Minnesota stand-alone Technical College SHOULD NOT USE THIS
									APPLICATION. Instead, apply directly to the college.
								</p>
								<p>
									Private colleges do NOT participate in the tuition reciprocity program because they
									do not charge non-resident tuition rates.
								</p>
								<p>
									Professional students enrolling in a Doctor of Medicine, Doctor of Dental Sciences,
									or Doctor of Veterinary Medicine program in the public institutions of either state
									are ineligible for reciprocity tuition.
								</p>
							</>
						)
					},
					{
						header: 'I already received reciprocity benefits and applied under WHEAB. Do I need to reapply?',
						item: (
							<p>
								Students enrolled under reciprocity who earned credits that were reported by the
								institution to the WHEAB during any semester of the current academic year will
								automatically have benefits renewed for the upcoming academic year at the institution
								they are currently attending. Therefore, these students need not reapply.
							</p>
						)
					}
				]}
			/>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(UserActions.logout())
});

export default withRouter(connect(null, mapDispatchToProps)(MN_Reciprocity));
