import { months_passed, years_passed } from 'components/modules/date';

export const REGEXES = {
		email: new RegExp(
			/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
		),
		dob: '^(0[1-9]|1[0-2])/([0-2][0-9]|(3)[0-1])/(19|20)\\d{2}$',
		zip: country => {
			const international_zip_regex = '^[A-Za-z\\d\\.\\- ]{0,10}$',
				zip_regexes = {
					USA: '^\\d{5}(?:-\\d{4})?$',
					CAN: '[A-Za-z]\\d[A-Za-z] \\d[A-Za-z]\\d'
				};

			if (!country) return international_zip_regex;

			return zip_regexes[country] || international_zip_regex;
		}
	},
	IS_VALID = {
		date_of_birth: dob => {
			if (dob?.match(REGEXES.dob)) {
				const age = years_passed(dob);
				return age >= 10 && age <= 110;
			}

			return !dob.length;
		},
		ssn: _ssn => {
			const ssn = `${_ssn || ''}`;

			return (
				ssn.length === 11 &&
				ssn !== '123-45-6789' &&
				!ssn.startsWith('9') &&
				!ssn.startsWith('666') &&
				!ssn.startsWith('000') &&
				!ssn.endsWith('0000') &&
				!ssn.includes('-00-')
			);
		},
		address: addr => {
			if (!addr) return false;

			const { address_1, city, country, state, other_country, zip } = addr;

			return (
				!!address_1 &&
				!!country &&
				(country !== 'USA' || !!state) &&
				(country !== 'Other' || !!other_country) &&
				!!city &&
				(!['USA', 'CAN'].includes(country) || !!zip) &&
				!!zip.match(REGEXES.zip(country))
			);
		},
		phone: _phone => {
			const phone = _phone || '',
				strs = phone.split('-');

			if (strs.length < 2) return true; // empty phone; handle elsewhere

			const country_code = strs[0],
				number = strs[1];

			if (country_code === '1') return number.length === 10;

			return true;
		},
		grad_date: grad_date => {
			if (grad_date.length !== 7) return false;

			const months_since_grad = months_passed(grad_date);

			return months_since_grad < 82 * 12 && months_since_grad > -4 * 12;
		}
	},
	addrIsPOBox = addr => {
		// checks whether or not the input begins with some variation of "P.O. Box"

		if ((addr.country || 'USA') === 'USA') {
			if (
				!(addr.address_1 || '')
					.replace(/[^a-zA-Z\d]+/g, '')
					.toLowerCase()
					.indexOf('pobox')
			) {
				return 'address_1';
			}

			if (
				!(addr.address_2 || '')
					.replace(/[^a-zA-Z\d]+/g, '')
					.toLowerCase()
					.indexOf('pobox')
			) {
				return 'address_2';
			}
		}

		return '';
	};
