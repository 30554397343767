import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { REGEXES } from 'components/modules/validation';
import { cleanString } from 'components/modules/_misc';

export default class ZipInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hide_invalid: true,
			pattern: REGEXES.zip(props.country)
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { value, country } = this.props,
			{ pattern } = this.state;

		if (country !== prevProps.country) this.setState({ pattern: REGEXES.zip(country) });

		if (value.match(pattern) && (!prevProps.value.match(pattern) || !value.match(prevState.pattern))) {
			this.setState({ hide_invalid: false });
		}
	}

	render() {
		let { disabled, value, onChange, name, label, required, cols, hide_optional, classVals, country } = this.props,
			{ pattern } = this.state,
			{ hide_invalid } = this.state,
			highlight_invalid = !hide_invalid && ((required && !value) || (!!value && !value.match(pattern))),
			input_classes = classVals || [];

		if (!onChange) input_classes.push('disabledInput');

		let input_props = {
			type: 'text',
			id: name,
			name: name,
			value: value,
			placeholder: 'Zip Code',
			required: required,
			maxLength: 10,
			'data-name': label,
			'aria-label': label,
			className: input_classes.join(' '),
			onBlur: () => this.setState({ hide_invalid: false }),
			pattern: pattern
		};

		if (onChange) {
			input_props.onChange = e => {
				let val = e.target.value || '',
					valClean = cleanString(val);

				if (val.length > value?.length) {
					val = valClean.clean;
					if (valClean.dirty) {
						confirmAlert({
							customUI: ({ onClose }) => {
								return (
									<div className='react-confirm-alert'>
										<div className='react-confirm-alert-body'>
											Invalid characters were removed from {`"${label}."` || 'your input.'}
											<div className='react-confirm-alert-button-group'>
												<button onClick={onClose}>OK</button>
											</div>
										</div>
									</div>
								);
							}
						});
					}

					if (country === 'USA') {
						val = val.replace(/[^0-9]/g, '');
						if (val.length > 5) val = `${val.substring(0, 5)}-${val.substring(5)}`;
					}

					if (country === 'CAN') {
						val = val.toUpperCase().replace(/[^0-9A-Z/s]/g, '');
						if (val.length > 3) val = `${val.substring(0, 3)} ${val.substring(3)}`;
					}
				}

				onChange(val);
			};
		} else {
			input_props.disabled = true;
		}

		if (disabled) input_props.disabled = true;

		return (
			<div className={`col-md-${cols}${highlight_invalid ? ' invalid' : ''}`}>
				<FormGroup>
					{label && (
						<label htmlFor={name}>
							{label}
							{!(required || hide_optional || input_props.disabled) && <small>(Optional)</small>}
						</label>
					)}

					<FormControl {...input_props} />
				</FormGroup>
			</div>
		);
	}
}

ZipInput.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	required: PropTypes.bool,
	country: PropTypes.string,
	cols: PropTypes.number.isRequired,
	hide_optional: PropTypes.bool,
	classVals: PropTypes.array
};
